<template>
  <div class="canvas-container">
    <canvas ref="canvas"></canvas>
  </div>
</template>
<script lang="ts">
import {Options, Prop, Vue, Watch} from "vue-property-decorator";
import ChartDataModel from "@/views/components/chartDataModel";
import Chart, {ChartType} from 'chart.js/auto';
import moment from "moment";


@Options({
  name: "chart-statistic",
})
export default class UiChartComponent extends Vue {
  @Prop({default: new ChartDataModel()}) chartModel: ChartDataModel;
  @Prop({default: 'bar'}) type: ChartType = "bar";

  cloneDeep = require('lodash.clonedeep');


  data: any = {}

  canvas: HTMLCanvasElement;
  chart: any;
  innerChange = 0;
  updateId: number = null;

  @Watch("chartModel", {deep: true})
  update(newValue, oldValue) {
    let newData = JSON.parse(JSON.stringify(this.chartModel));
    let tmpLabels = [];
    tmpLabels = tmpLabels.splice(0, tmpLabels.length);
    newData.labels.forEach(el => {
      tmpLabels.push(moment(el).utc().format("DD.MM.YYYY HH:mm:SS"));
    })
    newData.labels = tmpLabels;
    this.data = newData;

    this.chart.destroy();
    this.renderChart();
    // this.chart.update();
  }


  mounted() {
    this.data = this.chartModel
    this.canvas = this.$refs.canvas as HTMLCanvasElement;
    this.renderChart();
  }

  selectRangeStart(evt) {
  }

  selectRangeMove(evt) {
  }

  selectRangeEnd(evt) {
  }

  renderChart() {
    if (this.data.datasets.length == 2) {
      const ctx = this.canvas.getContext("2d");
      this.chart = new Chart(ctx, {
        type: this.type,
        data: this.data,
        options: {
          scales: {
            x: {
              ticks: {
                callback: function (val, index) {
                  let newData = JSON.parse(JSON.stringify(this.chartModel));
                  let tmpLabels = [];
                  tmpLabels = tmpLabels.splice(0, tmpLabels.length);
                  newData.labels.forEach(el => {
                    tmpLabels.push(moment(el).utc().format("HH:mm:SS").toString());
                  })
                  newData.labels = tmpLabels;
                  return newData.labels[index]
                }.bind(this)
              }
            },
            y: {
              type: "linear",
              display: true,
              position: "left",
              title: {
                display: true,
                text: this.data.datasets[0].label,
              },
            },
            y1: {
              type: "linear",
              display: true,
              position: "right",
              title: {
                display: true,
                text: this.data.datasets[1].label,
              },
              // grid line settings
              grid: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
              },
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                title: function (context) {
                  const title = context[0].label
                  return title
                }
              }
            },
            legend: {
              labels: {
                usePointStyle: true,
              },
            },
          },
        },
      });
    } else if (this.data.datasets.length == 1) {
      const ctx = this.canvas.getContext("2d");
      this.chart = new Chart(ctx, {
        type: this.type,
        data: this.data,
        options: {
          scales: {
            x: {
              ticks: {
                callback: function (val, index) {
                  let newData = JSON.parse(JSON.stringify(this.chartModel));
                  let tmpLabels = [];
                  tmpLabels = tmpLabels.splice(0, tmpLabels.length);
                  newData.labels.forEach(el => {
                    tmpLabels.push(moment(el).utc().format("HH:mm:SS").toString());
                  })
                  newData.labels = tmpLabels;
                  return newData.labels[index]
                }.bind(this)
              }
            },
            y: {
              type: "linear",
              display: true,
              position: "left",
              title: {
                display: true,
                text: this.data.datasets[0].label,
              },
            },
          },
          plugins: {
            legend: {
              labels: {
                usePointStyle: true,
              },
            },
          },
        },
      });
    }
  }
}
</script>
<style lang="less" scoped>
.canvas-container {
  width: 95%;
  margin: auto;
}
</style>
